import logo from './logo.png';
import sc1 from './sc1.png';
import sc2 from './sc2.png';
import qrcode from './qrcode.png';
import './App.css';

export default function MyApp() {
  const screenWidth = window.innerWidth;
    return (
    <div className="App">
      <header className="App-header">
    <h1>GSRadio</h1>
    <h2>האפליקציה מיועדת למכשירי מובייל בלבד</h2>
    <h2>אפשר לסרוק את הקוד כדי להתקין</h2>
    <h2>או ללחוץ על התמונה</h2>
    <table>
      <tr>
        <td>
          <img src={sc1}  width={screenWidth/4}  style={{padding:50,maxWidth:screenWidth/4}}  />
        </td>
        <td>
          <a href="http://onelink.to/8t8xjn">
          <img src={qrcode} width={screenWidth/4} style={{maxWidth:screenWidth/4}}  />
          </a>
          <br/><br/>
          <a href="https://gsradio.greenshpits.com/gsradio_privacy_policy.html">
          <h1>
          privacy policy
          </h1>
          </a>
          <br/><br/>
          <a href="https://gsradio.greenshpits.com/gsradio_eula.html">
          <h1>
          End User License Agreemebt (EULA)
          </h1>
          </a>
        </td>
        <td>
        <img src={sc2}  width={screenWidth/4}  style={{padding:50,maxWidth:screenWidth/4}} />
          </td>
      </tr>
    </table>
    </header>
    </div>
  );
}
